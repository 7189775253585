import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './shared/components/error/error.component';

const AppRoutes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: 'error/:status', component: ErrorComponent },

  /* PORTAL DE PAGOS (LINK) */
  {
    path: ':idlink',
    loadChildren: './payments-link/payments-link.module#PaymentsLinkModule',
  },

  { path: '**', redirectTo: 'no-link', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
