import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { User, UserManager } from 'oidc-client';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {

  mgr = new UserManager(environment.ids_settings);

  currentUser: User;
  authHeaders: HttpHeaders;

  loggedIn = false;

  constructor(private httpClient: HttpClient) {
    this.initUser();

    this.mgr.events.addUserLoaded(user => {
      this.currentUser = user;
    });

    this.mgr.events.addUserUnloaded((e) => {
      this.loggedIn = false;
    });
  }

  private initUser() {
    this.mgr.getUser()
      .then((user) => {
        if (user) {
          this.loggedIn = true;
          this.currentUser = user;
        } else {
          this.loggedIn = false;
        }
      })
      .catch((error) => {
        console.log('auth.service -> error: ', error);
        this.loggedIn = false;
      });
  }

  getUser() {
    this.mgr.getUser().then((user) => {
      console.log('auth.service -> got user', user);
      this.currentUser = user;
    }).catch(function (err) {
      console.log('auth.service -> ', err);
    });
  }

  getCurrentUser(): Observable<User | null> {
    return from(this.mgr.getUser());
  }

  removeUser() {
    this.mgr.removeUser().then(() => {
      console.log('auth.service -> user removed');
    }).catch(function (err) {
      console.log('auth.service -> ', err);
    });
  }

  clearState() {
    this.mgr.clearStaleState().then(function () {
      console.log('auth.service -> clearStateState success');
    }).catch(function (e) {
      console.log('auth.service -> clearStateState error', e.message);
    });
  }

  isLoggedInObs(): Observable<boolean> {
    return from(this.mgr.getUser())
      .pipe(
        tap(user => this.currentUser = user),
        map<User, boolean>(user => user !== null)
    );
  }

  startSignInMainWindow(): Promise<any> {
    return this.mgr.signinRedirect({ data: 'some data' });
  }

  startSignOutMainWindow() {
    this.mgr.signoutRedirect()
      .then(resp => {
        console.log('auth.service -> signed out', resp);
      })
      .catch(error => {
        console.log('auth.service -> Start signout main window', error);
      });
  }

  /**
   * Example of how you can make auth request using angulars httpClient methods.
   * @param options if options are not supplied the default content type is application/json
   */
  AuthGet<T>(url: string, params?: HttpParams): Observable<T> {
    this._setAuthHeaders(this.currentUser);

    if (params) {
      return this.httpClient.get<T>(url, { headers: this.authHeaders, params: params });
    }

    return this.httpClient.get<T>(url, { headers: this.authHeaders });
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPut<T>(url: string, body: any, params?: HttpParams): Observable<T> {
    this._setAuthHeaders(this.currentUser);

    let data = '';
    if (body) {
      data = JSON.stringify(body);

      if (params) {
        return this.httpClient.put<T>(url, data, { headers: this.authHeaders, params: params });
      }
    }

    return this.httpClient.put<T>(url, data, { headers: this.authHeaders });
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPutWithResponse<T>(url: string, body: any, params?: HttpParams): Observable<HttpResponse<T>> {
    this._setAuthHeaders(this.currentUser);

    let data = '';
    if (body) {
      data = JSON.stringify(body);

      if (params) {
        return this.httpClient.put<T>(url, data, { headers: this.authHeaders, observe: 'response', params: params });
      }
    }

    return this.httpClient.put<T>(url, data, { headers: this.authHeaders, observe: 'response' });
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthDelete<T>(url: string, params?: HttpParams): Observable<T> {
    this._setAuthHeaders(this.currentUser);

    if (params) {
      return this.httpClient.delete<T>(url, { headers: this.authHeaders, params: params });
    }

    return this.httpClient.delete<T>(url, { headers: this.authHeaders });
  }

  /**
   * @param options if options are not supplied the default content type is application/json
   */
  AuthPost<T>(url: string, body: any, params?: HttpParams): Observable<T> {
    this._setAuthHeaders(this.currentUser);
    let data = '';

    if (body) {
      data = JSON.stringify(body);

      if (params) {
        return this.httpClient.post<T>(url, data, { headers: this.authHeaders, params: params });
      }
    }

    return this.httpClient.post<T>(url, data, { headers: this.authHeaders });
  }

  private _setAuthHeaders(user: any) {
    if (user != null) {
      const userToken = user.token_type + ' ' + user.access_token;
      this.authHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': userToken });
    }
  }
}
