<!DOCTYPE html>
<html lang="es">

<head>
  <title>VK-Payment-Link</title>
  <meta http-equiv="refresh" content={{redirectContent}}>
</head>

<body>
  <div class="cover">
    <h1>{{errorTitle}}
      <small>{{status}}</small>
    </h1>
    <p class="lead">{{errorMsg}}</p>
  </div>
</body>

</html>
