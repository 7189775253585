import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class SharedApiService {

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  error(oidcClientName: string, messageError: string) {
    const url = environment.shared_webapi.base_url
      + environment.shared_webapi.errors;

    const body = {
      'oidcClientName': oidcClientName,
      'messageError': messageError
    };

    this.httpClient.post(url, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).subscribe(
      response => {
        console.log('api.service -> error - response: ', response);
      },
      error => {
        console.log('api.service -> error - error: ', error);
      }
    );

  }

  getTickets(guid: string) {
    function success(response) {
      const redirectURL = environment.landing_url + JSON.stringify(response).replace(/['"\\]+/g, '');
      if (redirectURL !== '-1') {
        window.location.href = redirectURL;
      }
    }

    function complete() {
      console.log('getTickets request completed');
    }

    const url = environment.webapi.base_url + 'integration/tickets';
    const body = { urlRefererId: guid };
    this.authService.AuthPost(url, body, null)
      .subscribe(response => success(response), () => this.authService.startSignOutMainWindow(), complete);
  }

  goToRedVitnik() {
    const guid = '0fec46c0-f233-4503-b9dc-8b0d7ecb9416';
    this.getTickets(guid);
  }

}
