import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class HttpErrorsService implements HttpInterceptor {

  constructor(private router: Router) { }

  private errorHandler(error: any) {
    console.log(error);
    if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
      this.router.navigate(['logout']);
    }
  }

  private requestHandler(event: HttpEvent<any>) {
    if (event instanceof HttpResponse && (event.status === 401 || event.status === 403)) {
      this.router.navigate(['error']);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
      tap(event => this.requestHandler(event), error => this.errorHandler(error))
      );
  }

}
