import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { SharedApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  status: any;
  errorTitle: any;
  errorMsg: any;
  redirectContent: any;

  constructor(private authService: AuthService, private route: ActivatedRoute, private sharedApiService: SharedApiService) {
    const status = this.route.snapshot.params['status'];

    if (status !== undefined) {
      this.status = status;
    }

    switch (this.status) {
      case 'notLoggedIn': {
        this.errorTitle = 'Usuario no logueado';
        this.errorMsg = 'Inicie sesión e intente nuevamente. Redirigiendo...';
        this.status = '';
        this.redirectContent = environment.error_redirect_time + environment.error_redirect_url.logout_url;
        break;
      }
      case 'date': {
        const authError = localStorage.getItem('authError');
        localStorage.removeItem('authError');
        this.sharedApiService.error(environment.ids_settings.client_id, authError);

        this.errorTitle = 'Fecha y Hora incorrecta';
        this.errorMsg = 'Verifique la fecha y hora de su equipo e intente nuevamente. Redirigiendo...';
        this.status = '';
        this.redirectContent = environment.error_redirect_time + environment.error_redirect_url.logout_url;
        break;
      }
      case '401': {
        this.errorTitle = 'Acceso no autorizado';
        this.errorMsg = 'El recurso solicitado requiere autenticación. Redirigiendo...';
        this.status = 'Error 401';
        this.redirectContent = environment.error_redirect_time + environment.error_redirect_url.logout_url;
        break;
      }
      case '403': {
        this.errorTitle = 'Acceso denegado';
        this.errorMsg = 'El recurso solicitado requiere autenticación. Redirigiendo...';
        this.status = 'Error 403';
        this.redirectContent = environment.error_redirect_time + environment.error_redirect_url.logout_url;
        break;
      }
      default: {
        this.errorTitle = 'No se ha podido acceder al recurso';
        this.errorMsg = 'Ocurrió un error al procesar la solicitud. Redirigiendo...';
        this.status = '';
        this.redirectContent = environment.error_redirect_time + environment.error_redirect_url.default;
        break;
      }
    }
  }
}
