export const environment = {
  production: false,

  ids_settings: {
    authority: 'https://webdevauth.vitnik.com/',
    client_id: '/PortalVitnik',
    redirect_uri: '',
    post_logout_redirect_uri: '',
    response_type: 'id_token token',
    scope: 'openid profile api1',
    filterProtocolClaims: true,
    loadUserInfo: true
  },
  landing_url: 'https://webdev.vitnik.com/',
  error_redirect_time: '5; ',
  error_redirect_url: {
    logout_url: '',
    default: ''
  },
  webapi: {
    base_url: 'https://webdevapi.vitnik.com/api/web/v1/'
  },
  shared_webapi: {
    base_url: 'https://webdevapi.vitnik.com/api/shared/v1/',
    errors: 'Errors'
  },
  payment_settings: {
    sps_decidir: {
      default_publishable_key: '96e7f0d36a0648fb9a8dcb50ac06d260',
      supervielle_publishable_key: '96e7f0d36a0648fb9a8dcb50ac06d261',
      vk_api_url: 'payments/SpsDecidir/',
      sps_api_url: 'https://developers.decidir.com/api/v2/'
    },
    mercado_pago: {
      publishable_key: 'TEST-c80ec350-cb18-432e-bd7d-99be1b328bb2',
      vk_api_url: 'Payments/MercadoPago/'
    }
  }
};
